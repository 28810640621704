import {Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {MenuItem} from 'primeng/api';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public esLogin: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private $auth: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.$auth.isAuthenticated.subscribe(auth => {
      if(location.pathname === '/') {
        console.log("auth", auth, location.pathname)
        this.esLogin = auth;
      } else {
        console.log("estoy aqui", auth, location.pathname)
        this.esLogin = true;
      };
    });
  };
  
  
  menuItems: MenuItem[] = [
    {
      label: 'Ticket',
      icon: 'pi pi-fw pi-file',
      items: [
        {
          label: 'Nuevo',
          icon: 'pi pi-plus-circle',
          routerLink: 'ticket/new'
        },
        {
          label: 'Abiertos',
          icon: 'pi pi-file',
          routerLink: 'ticket/open'
        },
        {
          label: 'Historial',
          icon: 'pi pi-folder',
          routerLink: 'ticket/closed'
        },
        {
          separator: true
        },
        {
          label: 'Reportes',
          icon: 'pi pi-book',
          routerLink: 'ticket/reports'
        }
      ]
    },
    {
      label: 'Tarjeta',
      icon: 'pi pi-fw pi-pencil',
      routerLink: 'tarjeta'
    },
    {
      label: 'Billetera',
      icon: 'pi pi-wallet',
      routerLink: 'billetera'
    },
    {
      label: 'Persona',
      icon: 'pi pi-fw pi-user',
      routerLink: 'persona'
    },
    {
      label: 'Pos',
      icon: 'pi pi-fw pi-ticket',
      routerLink: 'validador'
    },
    {
      label: 'Contador',
      icon: 'pi pi-fw pi-calendar',
      routerLink: 'contador'
    },
    {
      label: 'Mapa',
      icon: 'pi pi-map-marker',
      routerLink: 'map'
    },
    {
      label: 'Logout',
      icon: 'pi pi-fw pi-power-off',
      routerLink: 'login',
      command: () => {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('a_token');
        localStorage.removeItem('r_token');
        this.router.navigate(['login']);
        location.reload();
      }
    }
  ];
}