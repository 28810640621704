
export const environment = {
  production: false,
  PROTOCOL: 'https://',
  CENTRAL_HOST: 'central-qa.z1.mastarjeta.net',
  BUSES_HOST: 'buses-qa.z1.mastarjeta.net',
  BILLETERA_HOST: 'billetera-dev.z1.mastarjeta.net' 
};
// export const environment = {
//   production: false,
//   PROTOCOL: 'http://',
//   CENTRAL_HOST: 'localhost:8002',
//   BUSES_HOST: 'buses-qa.z1.mastarjeta.net'
// };
