import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const currentUser = this.auth.currentUserValue;

    await this.auth.renewToken();

    if (currentUser) {
      if (route.data && route.data.roles) {
        for (const rol of route.data.roles) {
          if (currentUser.rol === rol ) {
            return true;
          }
        }
      } else {
        return true;
      }
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login']);
    return false;
  }

}
