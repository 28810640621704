import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {JwtModule} from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import localePY from '@angular/common/locales/es-PY';
import { registerLocaleData } from '@angular/common';
import {AuthModule} from './auth/auth.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {MenubarModule} from 'primeng/menubar';
import { NotFoundComponent } from './misc/not-found/not-found.component';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ServiceWorkerModule} from '@angular/service-worker';
import { CommonModule } from '@angular/common';


registerLocaleData(localePY, 'es-PY');

function tokenGetter(): string {
  return localStorage.getItem('a_token');
}

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
  
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MenubarModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        allowedDomains: [
          environment.CENTRAL_HOST,
          environment.BUSES_HOST
        ],
        disallowedRoutes: [
          `${environment.PROTOCOL}${environment.CENTRAL_HOST}/token/`,
          `${environment.PROTOCOL}${environment.CENTRAL_HOST}/token/refresh/`,
        ],
      },
    }),
    AuthModule,
    ReactiveFormsModule,
    FormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production})
    
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
