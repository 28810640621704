import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {NotFoundComponent} from './misc/not-found/not-found.component';
import {AuthGuard} from './auth/auth.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/ticket/open',
    pathMatch: 'full' 
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'ticket',
    loadChildren: () => import('./ticket/ticket.module').then(m => m.TicketModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tarjeta',
    loadChildren: () => import('./tarjeta/tarjeta.module').then(m => m.TarjetaModule),
    canActivate: [AuthGuard]
    
  },
  {
    path: 'persona',
    loadChildren: () => import('./persona/persona.module').then(m => m.PersonaModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'map',
    loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'billetera',
    loadChildren: () => import('./billetera/billetera.module').then(m => m.BilleteraModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'contador',
    loadChildren: () => import('./contador/contador.module').then(m => m.ContadorModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pendiente',
    loadChildren: () => import('./pendiente/pendiente.module').then(m => m.PendienteModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'validador',
    loadChildren: () => import('./validador/validador.module').then(m => m.ValidadorModule),
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
